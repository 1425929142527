import React from 'react'
import Navbar from '../Navbar'
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Footer from '../Footer';
import Lottie from "lottie-react";
import flightData from "../assets/flight.json";
import BannerBg from "../../img/flight1.png";
import flightIcon from '../images/flighticon2.gif';

function Icon({ id, open }: { id: any; open: any }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

function BookFlight() {
    const navigate = useNavigate();


    return (
        <>
            <Navbar />
            <section style={{
                backgroundImage: `url(${BannerBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
            }}
                className="md:bg-cover bg-contain mx-auto  flex flex-col relative bg-[#209D5A]">

                <div className=''>
                    <div className="grid  px-8 lg:px-20  pb-6 pt-28  lg:gap-8 xl:gap-0 lg:pt-16 lg:grid-cols-12">
                        <div className="mr-auto place-self-center lg:col-span-6">

                            <h1 className="max-w-2xl mb-4 text-[30px] text-[#fff] font-bold md:leading-[80px] tracking-normal  md:text-[64px] ">
                                {/* Your Tuition <br />Payments <br />Made */}
                                Discover and Secure the Most Budget-Friendly Flights with Ease 
                                {/* Easily Find and <br />Book the Most <br />Affordable Flights */}
                            </h1>

                            <p className="hidden md:block max-w-[520px] mb-6 mt-3 font-normal text-[#fff] lg:mb-8 lg:text-base text-[12px] break-all">
                                {/* Find Unbeatable Deals for Your Study, Work, and <br />Travel Journeys. */}
                                Unlock Unbeatable Deals Tailored for Your Study, Work, <br/>and Travel Adventures
                            </p>
                            <p className="block md:hidden mb-6 mt-3 font-normal text-[#fff] lg:mb-8 lg:text-base text-[12px] break-all">
                                {/* Find Unbeatable Deals for Your Study, Work, and <br />Travel Journeys. */}
                                Unlock Unbeatable Deals Tailored for Your Study, <br/>Work, and Travel Adventures
                            </p>

                            <div className="flex mt-5">
                                <NavLink to="/bookflight" className="">
                                    <button
                                        type="submit"
                                        className="py-[14px] pl-[15px] flex gap-1 text-[16px] font-normal text-white bg-[#0167A0] hover:bg-[#5BBEF5] rounded-[8px] "
                                    >
                                        Book a flight now 
                                       
                                        <img src="/images/flighticon2.gif" alt="icon" className='w-[58px] h-[24px]'/> 
                                        
                                    </button>
                                </NavLink>
                            </div>

                            {/* <div className='flex space-x-2 mt-7' >
                            <p className='text-[20px] text-[#92CFAE] mt-3'>Powered by</p>
                            <div className="lg:mt-0">
                            <img src="/images/travelstart1.svg" alt="travel-start" />
              </div>
                        </div> */}
                        </div>
                        <div className=" my-6 lg:mt-[40px] lg:col-span-6 lg:flex w-full md:mt-0 mt-[50px]">
                            <img src="/images/flight-img.png" alt="hero" />
                        </div>
                    </div>

                </div>
            </section>

            <section className="bg-[#FFF]">
                <div className=" px-8 lg:px-16 pb-8  lg:pt-16 pt-8">
                    <div className="">
                        <h1 className="lg:text-[38px] text-[25px] text-[#191A15] font-bold text-center">
                            Explore Our Exclusive Deals
                        </h1>
                    </div>



                    <div className="grid lg:grid-cols-4 grid-cols-1 gap-5 pt-5">
                        <div className='relative lg:mb-40 mb-[240px]'>
                            <div>
                                <img
                                    src="images/flight1.png"
                                    alt="Bridge"
                                    className="rounded-t-[16px]"
                                />
                            </div>
                            <div className=' absolute  lg:-bottom-44 w-full bg-white px-3 py-[30px] rounded-[16px] shadow-md'>
                                <div className='flex justify-between'>
                                    <div className='flex space-x-2'>
                                        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.9987 1.33337C11.6807 1.33337 14.6654 4.31804 14.6654 8.00004C14.6654 11.682 11.6807 14.6667 7.9987 14.6667C4.3167 14.6667 1.33203 11.682 1.33203 8.00004C1.33203 4.31804 4.3167 1.33337 7.9987 1.33337ZM7.9987 2.66671C6.58421 2.66671 5.22766 3.22861 4.22746 4.2288C3.22727 5.229 2.66536 6.58555 2.66536 8.00004C2.66536 9.41453 3.22727 10.7711 4.22746 11.7713C5.22766 12.7715 6.58421 13.3334 7.9987 13.3334C9.41319 13.3334 10.7697 12.7715 11.7699 11.7713C12.7701 10.7711 13.332 9.41453 13.332 8.00004C13.332 6.58555 12.7701 5.229 11.7699 4.2288C10.7697 3.22861 9.41319 2.66671 7.9987 2.66671ZM7.9987 4.00004C8.16199 4.00006 8.31959 4.06001 8.44161 4.16852C8.56364 4.27702 8.64159 4.42654 8.6607 4.58871L8.66536 4.66671V7.72404L10.47 9.52871C10.5896 9.64868 10.659 9.80966 10.6642 9.97896C10.6694 10.1483 10.6099 10.3132 10.4979 10.4402C10.3858 10.5673 10.2297 10.6469 10.061 10.663C9.89243 10.679 9.72402 10.6303 9.59003 10.5267L9.52736 10.4714L7.52736 8.47137C7.42375 8.36767 7.35721 8.23271 7.33803 8.08737L7.33203 8.00004V4.66671C7.33203 4.4899 7.40227 4.32033 7.52729 4.1953C7.65232 4.07028 7.82189 4.00004 7.9987 4.00004Z" fill="#888888" />
                                        </svg> */}

                                        <span className='text-[10px] text-[#888888]'>Departure Date</span>
                                    </div>
                                    <div>
                                        <svg width="28" height="1" className='mt-1' viewBox="0 0 28 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="0.5" y1="0.5" x2="27.5" y2="0.5" stroke="#D7D7D7" />
                                        </svg>

                                    </div>
                                    <div className='flex space-x-2'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.9987 1.33337C11.6807 1.33337 14.6654 4.31804 14.6654 8.00004C14.6654 11.682 11.6807 14.6667 7.9987 14.6667C4.3167 14.6667 1.33203 11.682 1.33203 8.00004C1.33203 4.31804 4.3167 1.33337 7.9987 1.33337ZM7.9987 2.66671C6.58421 2.66671 5.22766 3.22861 4.22746 4.2288C3.22727 5.229 2.66536 6.58555 2.66536 8.00004C2.66536 9.41453 3.22727 10.7711 4.22746 11.7713C5.22766 12.7715 6.58421 13.3334 7.9987 13.3334C9.41319 13.3334 10.7697 12.7715 11.7699 11.7713C12.7701 10.7711 13.332 9.41453 13.332 8.00004C13.332 6.58555 12.7701 5.229 11.7699 4.2288C10.7697 3.22861 9.41319 2.66671 7.9987 2.66671ZM7.9987 4.00004C8.16199 4.00006 8.31959 4.06001 8.44161 4.16852C8.56364 4.27702 8.64159 4.42654 8.6607 4.58871L8.66536 4.66671V7.72404L10.47 9.52871C10.5896 9.64868 10.659 9.80966 10.6642 9.97896C10.6694 10.1483 10.6099 10.3132 10.4979 10.4402C10.3858 10.5673 10.2297 10.6469 10.061 10.663C9.89243 10.679 9.72402 10.6303 9.59003 10.5267L9.52736 10.4714L7.52736 8.47137C7.42375 8.36767 7.35721 8.23271 7.33803 8.08737L7.33203 8.00004V4.66671C7.33203 4.4899 7.40227 4.32033 7.52729 4.1953C7.65232 4.07028 7.82189 4.00004 7.9987 4.00004Z" fill="#888888" />
                                        </svg>

                                        <span className='text-[10px] text-[#888888]'>20th Feb 2025</span>
                                    </div>

                                </div>

                                <div className='flex justify-between px-3 pt-4'>
                                    <div>
                                        <h3 className='text-[18px] font-[600]'>Abuja</h3>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1024 1024"><path fill="#000" d="M847.9 592H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h605.2L612.9 851c-4.1 5.2-.4 13 6.3 13h72.5c4.9 0 9.5-2.2 12.6-6.1l168.8-214.1c16.5-21 1.6-51.8-25.2-51.8M872 356H266.8l144.3-183c4.1-5.2.4-13-6.3-13h-72.5c-4.9 0-9.5 2.2-12.6 6.1L150.9 380.2c-16.5 21-1.6 51.8 25.1 51.8h696c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8" /></svg>
                                    </div>
                                    <div>
                                        <h3 className='text-[18px] font-[600]'>London</h3>
                                    </div>

                                </div>

                                <div className='flex justify-between pt-4'>
                                    <div>
                                        <h3 className='text-[10px] text-[#888888]'>Business</h3>
                                        <h1 className='text-[18px] font-[600]'>₦2,296,424</h1>
                                    </div>
                                    <div>
                                        <svg width="1" height="27" className='mt-1' viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="0.5" y1="27" x2="0.499999" y2="2.18557e-08" stroke="#D7D7D7" />
                                        </svg>

                                    </div>
                                    <div>
                                        <h3 className='text-[10px] text-[#888888]'>Economy</h3>
                                        <h1 className='text-[18px] font-[600]'>₦1,242,398</h1>
                                    </div>
                                </div>

                                <div className='pt-[16px]'>
                                    <NavLink to="/bookflight">
                                        <button className="w-full bg-[#209D5A] hover:bg-[#016E33] text-white py-3 rounded-lg flex justify-center items-center gap-2 transition">
                                            Book a flight now
                                            <img src="/images/flighticon2.gif" alt="icon" className='w-[58px] h-[24px]'/> 
                                           
                                        </button>
                                    </NavLink>

                                </div>



                            </div>
                        </div>
                        <div className='relative lg:mb-40 mb-[240px]'>
                            <div>
                                <img
                                    src="images/flight2.png"
                                    alt="Bridge"
                                    className="rounded-t-[16px]"
                                />
                            </div>
                            <div className=' absolute  lg:-bottom-44 w-full bg-white px-3 py-[30px] rounded-[16px] shadow-md'>
                                <div className='flex justify-between'>
                                    <div className='flex space-x-2'>
                                        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.9987 1.33337C11.6807 1.33337 14.6654 4.31804 14.6654 8.00004C14.6654 11.682 11.6807 14.6667 7.9987 14.6667C4.3167 14.6667 1.33203 11.682 1.33203 8.00004C1.33203 4.31804 4.3167 1.33337 7.9987 1.33337ZM7.9987 2.66671C6.58421 2.66671 5.22766 3.22861 4.22746 4.2288C3.22727 5.229 2.66536 6.58555 2.66536 8.00004C2.66536 9.41453 3.22727 10.7711 4.22746 11.7713C5.22766 12.7715 6.58421 13.3334 7.9987 13.3334C9.41319 13.3334 10.7697 12.7715 11.7699 11.7713C12.7701 10.7711 13.332 9.41453 13.332 8.00004C13.332 6.58555 12.7701 5.229 11.7699 4.2288C10.7697 3.22861 9.41319 2.66671 7.9987 2.66671ZM7.9987 4.00004C8.16199 4.00006 8.31959 4.06001 8.44161 4.16852C8.56364 4.27702 8.64159 4.42654 8.6607 4.58871L8.66536 4.66671V7.72404L10.47 9.52871C10.5896 9.64868 10.659 9.80966 10.6642 9.97896C10.6694 10.1483 10.6099 10.3132 10.4979 10.4402C10.3858 10.5673 10.2297 10.6469 10.061 10.663C9.89243 10.679 9.72402 10.6303 9.59003 10.5267L9.52736 10.4714L7.52736 8.47137C7.42375 8.36767 7.35721 8.23271 7.33803 8.08737L7.33203 8.00004V4.66671C7.33203 4.4899 7.40227 4.32033 7.52729 4.1953C7.65232 4.07028 7.82189 4.00004 7.9987 4.00004Z" fill="#888888" />
                                        </svg> */}

                                        <span className='text-[10px] text-[#888888]'>Departure Date</span>
                                    </div>
                                    <div>
                                        <svg width="28" height="1" className='mt-1' viewBox="0 0 28 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="0.5" y1="0.5" x2="27.5" y2="0.5" stroke="#D7D7D7" />
                                        </svg>

                                    </div>
                                    <div className='flex space-x-2'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.9987 1.33337C11.6807 1.33337 14.6654 4.31804 14.6654 8.00004C14.6654 11.682 11.6807 14.6667 7.9987 14.6667C4.3167 14.6667 1.33203 11.682 1.33203 8.00004C1.33203 4.31804 4.3167 1.33337 7.9987 1.33337ZM7.9987 2.66671C6.58421 2.66671 5.22766 3.22861 4.22746 4.2288C3.22727 5.229 2.66536 6.58555 2.66536 8.00004C2.66536 9.41453 3.22727 10.7711 4.22746 11.7713C5.22766 12.7715 6.58421 13.3334 7.9987 13.3334C9.41319 13.3334 10.7697 12.7715 11.7699 11.7713C12.7701 10.7711 13.332 9.41453 13.332 8.00004C13.332 6.58555 12.7701 5.229 11.7699 4.2288C10.7697 3.22861 9.41319 2.66671 7.9987 2.66671ZM7.9987 4.00004C8.16199 4.00006 8.31959 4.06001 8.44161 4.16852C8.56364 4.27702 8.64159 4.42654 8.6607 4.58871L8.66536 4.66671V7.72404L10.47 9.52871C10.5896 9.64868 10.659 9.80966 10.6642 9.97896C10.6694 10.1483 10.6099 10.3132 10.4979 10.4402C10.3858 10.5673 10.2297 10.6469 10.061 10.663C9.89243 10.679 9.72402 10.6303 9.59003 10.5267L9.52736 10.4714L7.52736 8.47137C7.42375 8.36767 7.35721 8.23271 7.33803 8.08737L7.33203 8.00004V4.66671C7.33203 4.4899 7.40227 4.32033 7.52729 4.1953C7.65232 4.07028 7.82189 4.00004 7.9987 4.00004Z" fill="#888888" />
                                        </svg>

                                        <span className='text-[10px] text-[#888888]'>20th Feb 2025</span>
                                    </div>

                                </div>

                                <div className='flex justify-between px-3 pt-4'>
                                    <div>
                                        <h3 className='text-[18px] font-[600]'>Lagos</h3>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1024 1024"><path fill="#000" d="M847.9 592H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h605.2L612.9 851c-4.1 5.2-.4 13 6.3 13h72.5c4.9 0 9.5-2.2 12.6-6.1l168.8-214.1c16.5-21 1.6-51.8-25.2-51.8M872 356H266.8l144.3-183c4.1-5.2.4-13-6.3-13h-72.5c-4.9 0-9.5 2.2-12.6 6.1L150.9 380.2c-16.5 21-1.6 51.8 25.1 51.8h696c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8" /></svg>
                                    </div>
                                    <div>
                                        <h3 className='text-[18px] font-[600]'>London</h3>
                                    </div>

                                </div>

                                <div className='flex justify-between pt-4'>
                                    <div>
                                        <h3 className='text-[10px] text-[#888888]'>Business</h3>
                                        <h1 className='text-[18px] font-[600]'>₦2,159,018</h1>
                                    </div>
                                    <div>
                                        <svg width="1" height="27" className='mt-1' viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="0.5" y1="27" x2="0.499999" y2="2.18557e-08" stroke="#D7D7D7" />
                                        </svg>

                                    </div>
                                    <div>
                                        <h3 className='text-[10px] text-[#888888]'>Economy</h3>
                                        <h1 className='text-[18px] font-[600]'>₦783,587</h1>
                                    </div>
                                </div>

                                <div className='pt-[16px]'>
                                    <NavLink to="/bookflight">
                                        <button className="w-full bg-[#209D5A] hover:bg-[#016E33] text-white py-3 rounded-lg flex justify-center items-center gap-2 transition">
                                            Book a flight now
                                            <img src="/images/flighticon2.gif" alt="icon" className='w-[58px] h-[24px]'/> 
                                        </button>
                                    </NavLink>
                                </div>



                            </div>
                        </div>
                        <div className='relative lg:mb-40 mb-[240px]'>
                            <div>
                                <img
                                    src="images/flight3.png"
                                    alt="Bridge"
                                    className="rounded-t-[16px]"
                                />
                            </div>
                            <div className=' absolute  lg:-bottom-44 w-full bg-white px-3 py-[30px] rounded-[16px] shadow-md'>
                                <div className='flex justify-between'>
                                    <div className='flex space-x-2'>
                                        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.9987 1.33337C11.6807 1.33337 14.6654 4.31804 14.6654 8.00004C14.6654 11.682 11.6807 14.6667 7.9987 14.6667C4.3167 14.6667 1.33203 11.682 1.33203 8.00004C1.33203 4.31804 4.3167 1.33337 7.9987 1.33337ZM7.9987 2.66671C6.58421 2.66671 5.22766 3.22861 4.22746 4.2288C3.22727 5.229 2.66536 6.58555 2.66536 8.00004C2.66536 9.41453 3.22727 10.7711 4.22746 11.7713C5.22766 12.7715 6.58421 13.3334 7.9987 13.3334C9.41319 13.3334 10.7697 12.7715 11.7699 11.7713C12.7701 10.7711 13.332 9.41453 13.332 8.00004C13.332 6.58555 12.7701 5.229 11.7699 4.2288C10.7697 3.22861 9.41319 2.66671 7.9987 2.66671ZM7.9987 4.00004C8.16199 4.00006 8.31959 4.06001 8.44161 4.16852C8.56364 4.27702 8.64159 4.42654 8.6607 4.58871L8.66536 4.66671V7.72404L10.47 9.52871C10.5896 9.64868 10.659 9.80966 10.6642 9.97896C10.6694 10.1483 10.6099 10.3132 10.4979 10.4402C10.3858 10.5673 10.2297 10.6469 10.061 10.663C9.89243 10.679 9.72402 10.6303 9.59003 10.5267L9.52736 10.4714L7.52736 8.47137C7.42375 8.36767 7.35721 8.23271 7.33803 8.08737L7.33203 8.00004V4.66671C7.33203 4.4899 7.40227 4.32033 7.52729 4.1953C7.65232 4.07028 7.82189 4.00004 7.9987 4.00004Z" fill="#888888" />
                                        </svg> */}

                                        <span className='text-[10px] text-[#888888]'>Departure Date</span>
                                    </div>
                                    <div>
                                        <svg width="28" height="1" className='mt-1' viewBox="0 0 28 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="0.5" y1="0.5" x2="27.5" y2="0.5" stroke="#D7D7D7" />
                                        </svg>

                                    </div>
                                    <div className='flex space-x-2'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.9987 1.33337C11.6807 1.33337 14.6654 4.31804 14.6654 8.00004C14.6654 11.682 11.6807 14.6667 7.9987 14.6667C4.3167 14.6667 1.33203 11.682 1.33203 8.00004C1.33203 4.31804 4.3167 1.33337 7.9987 1.33337ZM7.9987 2.66671C6.58421 2.66671 5.22766 3.22861 4.22746 4.2288C3.22727 5.229 2.66536 6.58555 2.66536 8.00004C2.66536 9.41453 3.22727 10.7711 4.22746 11.7713C5.22766 12.7715 6.58421 13.3334 7.9987 13.3334C9.41319 13.3334 10.7697 12.7715 11.7699 11.7713C12.7701 10.7711 13.332 9.41453 13.332 8.00004C13.332 6.58555 12.7701 5.229 11.7699 4.2288C10.7697 3.22861 9.41319 2.66671 7.9987 2.66671ZM7.9987 4.00004C8.16199 4.00006 8.31959 4.06001 8.44161 4.16852C8.56364 4.27702 8.64159 4.42654 8.6607 4.58871L8.66536 4.66671V7.72404L10.47 9.52871C10.5896 9.64868 10.659 9.80966 10.6642 9.97896C10.6694 10.1483 10.6099 10.3132 10.4979 10.4402C10.3858 10.5673 10.2297 10.6469 10.061 10.663C9.89243 10.679 9.72402 10.6303 9.59003 10.5267L9.52736 10.4714L7.52736 8.47137C7.42375 8.36767 7.35721 8.23271 7.33803 8.08737L7.33203 8.00004V4.66671C7.33203 4.4899 7.40227 4.32033 7.52729 4.1953C7.65232 4.07028 7.82189 4.00004 7.9987 4.00004Z" fill="#888888" />
                                        </svg>

                                        <span className='text-[10px] text-[#888888]'>20th Feb 2025</span>
                                    </div>

                                </div>

                                <div className='flex justify-between px-3 pt-4'>
                                    <div>
                                        <h3 className='text-[18px] font-[600]'>Lagos</h3>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1024 1024"><path fill="#000" d="M847.9 592H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h605.2L612.9 851c-4.1 5.2-.4 13 6.3 13h72.5c4.9 0 9.5-2.2 12.6-6.1l168.8-214.1c16.5-21 1.6-51.8-25.2-51.8M872 356H266.8l144.3-183c4.1-5.2.4-13-6.3-13h-72.5c-4.9 0-9.5 2.2-12.6 6.1L150.9 380.2c-16.5 21-1.6 51.8 25.1 51.8h696c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8" /></svg>
                                    </div>
                                    <div>
                                        <h3 className='text-[18px] font-[600]'>Canada</h3>
                                    </div>

                                </div>

                                <div className='flex justify-between pt-4'>
                                    <div>
                                        <h3 className='text-[10px] text-[#888888]'>Business</h3>
                                        <h1 className='text-[18px] font-[600]'>₦3,269,530</h1>
                                    </div>
                                    <div>
                                        <svg width="1" height="27" className='mt-1' viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="0.5" y1="27" x2="0.499999" y2="2.18557e-08" stroke="#D7D7D7" />
                                        </svg>

                                    </div>
                                    <div>
                                        <h3 className='text-[10px] text-[#888888]'>Economy</h3>
                                        <h1 className='text-[18px] font-[600]'>₦1,910,041</h1>
                                    </div>
                                </div>

                                <div className='pt-[16px]'>
                                    <NavLink to="/bookflight">
                                        <button className="w-full bg-[#209D5A] hover:bg-[#016E33] text-white py-3 rounded-lg flex justify-center items-center gap-2 transition">
                                            Book a flight now
                                            <img src="/images/flighticon2.gif" alt="icon" className='w-[58px] h-[24px]'/> 
                                        </button>
                                    </NavLink>
                                </div>



                            </div>
                        </div>
                        <div className='relative lg:mb-40 mb-[240px]'>
                            <div>
                                <img
                                    src="images/flight4.png"
                                    alt="Bridge"
                                    className="rounded-t-[16px]"
                                />
                            </div>
                            <div className=' absolute  lg:-bottom-44 w-full bg-white px-3 py-[30px] rounded-[16px] shadow-md'>
                                <div className='flex justify-between'>
                                    <div className='flex space-x-2'>
                                        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.9987 1.33337C11.6807 1.33337 14.6654 4.31804 14.6654 8.00004C14.6654 11.682 11.6807 14.6667 7.9987 14.6667C4.3167 14.6667 1.33203 11.682 1.33203 8.00004C1.33203 4.31804 4.3167 1.33337 7.9987 1.33337ZM7.9987 2.66671C6.58421 2.66671 5.22766 3.22861 4.22746 4.2288C3.22727 5.229 2.66536 6.58555 2.66536 8.00004C2.66536 9.41453 3.22727 10.7711 4.22746 11.7713C5.22766 12.7715 6.58421 13.3334 7.9987 13.3334C9.41319 13.3334 10.7697 12.7715 11.7699 11.7713C12.7701 10.7711 13.332 9.41453 13.332 8.00004C13.332 6.58555 12.7701 5.229 11.7699 4.2288C10.7697 3.22861 9.41319 2.66671 7.9987 2.66671ZM7.9987 4.00004C8.16199 4.00006 8.31959 4.06001 8.44161 4.16852C8.56364 4.27702 8.64159 4.42654 8.6607 4.58871L8.66536 4.66671V7.72404L10.47 9.52871C10.5896 9.64868 10.659 9.80966 10.6642 9.97896C10.6694 10.1483 10.6099 10.3132 10.4979 10.4402C10.3858 10.5673 10.2297 10.6469 10.061 10.663C9.89243 10.679 9.72402 10.6303 9.59003 10.5267L9.52736 10.4714L7.52736 8.47137C7.42375 8.36767 7.35721 8.23271 7.33803 8.08737L7.33203 8.00004V4.66671C7.33203 4.4899 7.40227 4.32033 7.52729 4.1953C7.65232 4.07028 7.82189 4.00004 7.9987 4.00004Z" fill="#888888" />
                                        </svg> */}

                                        <span className='text-[10px] text-[#888888]'>Departure Date</span>
                                    </div>
                                    <div>
                                        <svg width="28" height="1" className='mt-1' viewBox="0 0 28 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="0.5" y1="0.5" x2="27.5" y2="0.5" stroke="#D7D7D7" />
                                        </svg>

                                    </div>
                                    <div className='flex space-x-2'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.9987 1.33337C11.6807 1.33337 14.6654 4.31804 14.6654 8.00004C14.6654 11.682 11.6807 14.6667 7.9987 14.6667C4.3167 14.6667 1.33203 11.682 1.33203 8.00004C1.33203 4.31804 4.3167 1.33337 7.9987 1.33337ZM7.9987 2.66671C6.58421 2.66671 5.22766 3.22861 4.22746 4.2288C3.22727 5.229 2.66536 6.58555 2.66536 8.00004C2.66536 9.41453 3.22727 10.7711 4.22746 11.7713C5.22766 12.7715 6.58421 13.3334 7.9987 13.3334C9.41319 13.3334 10.7697 12.7715 11.7699 11.7713C12.7701 10.7711 13.332 9.41453 13.332 8.00004C13.332 6.58555 12.7701 5.229 11.7699 4.2288C10.7697 3.22861 9.41319 2.66671 7.9987 2.66671ZM7.9987 4.00004C8.16199 4.00006 8.31959 4.06001 8.44161 4.16852C8.56364 4.27702 8.64159 4.42654 8.6607 4.58871L8.66536 4.66671V7.72404L10.47 9.52871C10.5896 9.64868 10.659 9.80966 10.6642 9.97896C10.6694 10.1483 10.6099 10.3132 10.4979 10.4402C10.3858 10.5673 10.2297 10.6469 10.061 10.663C9.89243 10.679 9.72402 10.6303 9.59003 10.5267L9.52736 10.4714L7.52736 8.47137C7.42375 8.36767 7.35721 8.23271 7.33803 8.08737L7.33203 8.00004V4.66671C7.33203 4.4899 7.40227 4.32033 7.52729 4.1953C7.65232 4.07028 7.82189 4.00004 7.9987 4.00004Z" fill="#888888" />
                                        </svg>

                                        <span className='text-[10px] text-[#888888]'>20th Feb 2025</span>
                                    </div>

                                </div>

                                <div className='flex justify-between px-3 pt-4'>
                                    <div>
                                        <h3 className='text-[18px] font-[600]'>Lagos</h3>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1024 1024"><path fill="#000" d="M847.9 592H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h605.2L612.9 851c-4.1 5.2-.4 13 6.3 13h72.5c4.9 0 9.5-2.2 12.6-6.1l168.8-214.1c16.5-21 1.6-51.8-25.2-51.8M872 356H266.8l144.3-183c4.1-5.2.4-13-6.3-13h-72.5c-4.9 0-9.5 2.2-12.6 6.1L150.9 380.2c-16.5 21-1.6 51.8 25.1 51.8h696c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8" /></svg>
                                    </div>
                                    <div>
                                        <h3 className='text-[18px] font-[600]'>Guangzhou</h3>
                                    </div>

                                </div>

                                <div className='flex justify-between pt-4'>
                                    <div>
                                        <h3 className='text-[10px] text-[#888888]'>Business</h3>
                                        <h1 className='text-[18px] font-[600]'>₦2,560,000</h1>
                                    </div>
                                    <div>
                                        <svg width="1" height="27" className='mt-1' viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="0.5" y1="27" x2="0.499999" y2="2.18557e-08" stroke="#D7D7D7" />
                                        </svg>

                                    </div>
                                    <div>
                                        <h3 className='text-[10px] text-[#888888]'>Economy</h3>
                                        <h1 className='text-[18px] font-[600]'>₦1,420,337</h1>
                                    </div>
                                </div>

                                <div className='pt-[16px]'>
                                    <NavLink to="/bookflight">
                                        <button className="w-full bg-[#209D5A] hover:bg-[#016E33] text-white py-3 rounded-lg flex justify-center items-center gap-2 transition">
                                            Book a flight now
                                            <img src="/images/flighticon2.gif" alt="icon" className='w-[58px] h-[24px]'/> 
                                        </button>
                                    </NavLink>
                                </div>



                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="pt-10  pb-10 bg-[#fff]">
                <div className="flex md:flex-row flex-col px-8 lg:px-16">
                    <div className="flex-1 flex justify-center items-start flex-col">
                        <div className="md:flex flex-col w-full">
                            <h1 className="hidden lg:block flex-1 md:text-[45px]  font-bold text-[#191A15] md:leading-[58px]">
                                How to book flights
                                <br /> with us
                            </h1>
                            <h1 className=" lg:hidden block flex-1  text-[20px] font-bold text-[#191A15] md:leading-[58px]">
                                How to book flights with us
                            </h1>
                            <p className='pt-1 text-[#989898] text-[20px]'>Just 5 easy and quick steps</p>
                        </div>

                    </div>
                    <div className="flex-1 flex justify-center items-center md:mt-0 mt-[50px]">
                        <img src="/images/flighttNeww.png" alt="hero" />


                    </div>
                </div>
                <div className="grid  px-8 lg:px-16 pb-10  lg:gap-8 xl:gap-0 lg:pt-10">
                    <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 pt-5">
                        <div className='bg-white shadow rounded-[16px] px-[12px] py-[25px]'>
                            <div className='flex space-x-3'>
                                <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="53" height="52" rx="26" fill="#209D5A" />
                                    <path d="M29.0085 17.5455V35H25.3182V21.0483H25.2159L21.2188 23.554V20.2812L25.5398 17.5455H29.0085Z" fill="white" />
                                </svg>

                                <div>
                                    <h3 className='text-[20px] text-[#0A0A0A] font-[600]'>Enter Your Travel Details</h3>
                                    <p className='pt-[6px] text-[16px] text-[#0A0A0A]'>Enter your departure city, arrival destination, and travel dates and search.</p>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white shadow rounded-[16px] px-[12px] py-[25px]'>
                            <div className='flex space-x-3'>
                                <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="53" height="52" rx="26" fill="#209D5A" />
                                    <path d="M21.4148 35V32.3409L27.6278 26.5881C28.1563 26.0767 28.5994 25.6165 28.9574 25.2074C29.321 24.7983 29.5966 24.3977 29.7841 24.0057C29.9716 23.608 30.0653 23.179 30.0653 22.7188C30.0653 22.2074 29.9489 21.767 29.7159 21.3977C29.483 21.0227 29.1648 20.7358 28.7614 20.5369C28.358 20.3324 27.9006 20.2301 27.3892 20.2301C26.8551 20.2301 26.3892 20.3381 25.9915 20.554C25.5938 20.7699 25.2869 21.0795 25.071 21.483C24.8551 21.8864 24.7472 22.3665 24.7472 22.9233H21.2443C21.2443 21.7812 21.5028 20.7898 22.0199 19.9489C22.5369 19.108 23.2614 18.4574 24.1932 17.9972C25.125 17.5369 26.1989 17.3068 27.4148 17.3068C28.6648 17.3068 29.7528 17.5284 30.679 17.9716C31.6108 18.4091 32.3352 19.017 32.8523 19.7955C33.3693 20.5739 33.6278 21.4659 33.6278 22.4716C33.6278 23.1307 33.4972 23.7812 33.2358 24.4233C32.9801 25.0653 32.5227 25.7784 31.8636 26.5625C31.2045 27.3409 30.2756 28.2756 29.0767 29.3665L26.5284 31.8636V31.983H33.858V35H21.4148Z" fill="white" />
                                </svg>


                                <div>
                                    <h3 className='text-[20px] text-[#0A0A0A] font-[600]'>Select the Flight Option</h3>
                                    <p className='pt-[6px] text-[16px] text-[#0A0A0A]'>Select the flight option most suited to your schedule and budget.</p>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white shadow rounded-[16px] px-[12px] py-[25px]'>
                            <div className='flex space-x-3'>
                                <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="53" height="52" rx="26" fill="#209D5A" />
                                    <path d="M27.8665 35.2386C26.5938 35.2386 25.4602 35.0199 24.4659 34.5824C23.4773 34.1392 22.696 33.5312 22.1222 32.7585C21.554 31.9801 21.2614 31.0824 21.2443 30.0653H24.9602C24.983 30.4915 25.1222 30.8665 25.3778 31.1903C25.6392 31.5085 25.9858 31.7557 26.4176 31.9318C26.8494 32.108 27.3352 32.196 27.875 32.196C28.4375 32.196 28.9347 32.0966 29.3665 31.8977C29.7983 31.6989 30.1364 31.4233 30.3807 31.071C30.625 30.7187 30.7472 30.3125 30.7472 29.8523C30.7472 29.3864 30.6165 28.9744 30.3551 28.6165C30.0994 28.2528 29.7301 27.9687 29.2472 27.7642C28.7699 27.5597 28.2017 27.4574 27.5426 27.4574H25.9148V24.7472H27.5426C28.0994 24.7472 28.5909 24.6506 29.017 24.4574C29.4489 24.2642 29.7841 23.9972 30.0227 23.6562C30.2614 23.3097 30.3807 22.9062 30.3807 22.446C30.3807 22.0085 30.2756 21.625 30.0653 21.2955C29.8608 20.9602 29.571 20.6989 29.196 20.5114C28.8267 20.3239 28.3949 20.2301 27.9006 20.2301C27.4006 20.2301 26.9432 20.321 26.5284 20.5028C26.1136 20.679 25.7812 20.9318 25.5312 21.2614C25.2812 21.5909 25.1477 21.9773 25.1307 22.4205H21.5938C21.6108 21.4148 21.8977 20.5284 22.4545 19.7614C23.0114 18.9943 23.7614 18.3949 24.7045 17.9631C25.6534 17.5256 26.7244 17.3068 27.9176 17.3068C29.1222 17.3068 30.1761 17.5256 31.0795 17.9631C31.983 18.4006 32.6847 18.9915 33.1847 19.7358C33.6903 20.4744 33.9403 21.304 33.9347 22.2244C33.9403 23.2017 33.6364 24.017 33.0227 24.6705C32.4148 25.3239 31.6222 25.7386 30.6449 25.9148V26.0511C31.929 26.2159 32.9063 26.6619 33.5767 27.3892C34.2528 28.1108 34.5881 29.0142 34.5824 30.0994C34.5881 31.0938 34.3011 31.9773 33.7216 32.75C33.1477 33.5227 32.3551 34.1307 31.3438 34.5739C30.3324 35.017 29.1733 35.2386 27.8665 35.2386Z" fill="white" />
                                </svg>
                                <div>
                                    <h3 className='text-[20px] text-[#0A0A0A] font-[600]'>Enter Passenger Details</h3>
                                    <p className='pt-[6px] text-[16px] text-[#0A0A0A]'>Insert the correct passenger details according to passport / ID book.</p>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white shadow rounded-[16px] px-[12px] py-[25px]'>
                            <div className='flex space-x-3'>
                                
                                <svg width="33" height="32" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="53" height="52" rx="26" fill="#209D5A"/>
<path d="M21.1506 31.9318V29.0256L28.4375 17.5455H30.9432V21.5682H29.4602L24.8665 28.8381V28.9744H35.2216V31.9318H21.1506ZM29.5284 35V31.0455L29.5966 29.7585V17.5455H33.0568V35H29.5284Z" fill="white"/>
</svg>

                                <div>
                                    <h3 className='text-[20px] text-[#0A0A0A] font-[600]'>Payment</h3>
                                    <p className='pt-[6px] text-[16px] text-[#0A0A0A]'>Select your preferred payment method</p>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white shadow rounded-[16px] px-[12px] py-[25px]'>
                            <div className='flex space-x-3'>
                            <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="53" height="52" rx="26" fill="#209D5A" />
                                    <path d="M27.7898 35.2386C26.5852 35.2386 25.5114 35.017 24.5682 34.5739C23.6307 34.1307 22.8864 33.5199 22.3352 32.7415C21.7841 31.9631 21.4972 31.071 21.4744 30.0653H25.054C25.0938 30.7415 25.3778 31.2898 25.9062 31.7102C26.4347 32.1307 27.0625 32.3409 27.7898 32.3409C28.3693 32.3409 28.8807 32.2131 29.3239 31.9574C29.7727 31.696 30.1222 31.3352 30.3722 30.875C30.6278 30.4091 30.7557 29.875 30.7557 29.2727C30.7557 28.6591 30.625 28.1193 30.3636 27.6534C30.108 27.1875 29.7528 26.8239 29.2983 26.5625C28.8438 26.3011 28.3239 26.1676 27.7386 26.1619C27.2273 26.1619 26.7301 26.267 26.2472 26.4773C25.7699 26.6875 25.3977 26.9744 25.1307 27.3381L21.8494 26.75L22.6761 17.5455H33.3466V20.5625H25.7188L25.267 24.9347H25.3693C25.6761 24.5028 26.1392 24.1449 26.7585 23.8608C27.3778 23.5767 28.071 23.4347 28.8381 23.4347C29.8892 23.4347 30.8267 23.6818 31.6506 24.1761C32.4744 24.6705 33.125 25.3494 33.6023 26.2131C34.0795 27.071 34.3153 28.0597 34.3097 29.179C34.3153 30.3551 34.0426 31.4006 33.4915 32.3153C32.946 33.2244 32.1818 33.9403 31.1989 34.4631C30.2216 34.9801 29.0852 35.2386 27.7898 35.2386Z" fill="white" />
                                </svg>
                                <div>
                                    <h3 className='text-[20px] text-[#0A0A0A] font-[600]'>Safe journey</h3>
                                    <p className='pt-[6px] text-[16px] text-[#0A0A0A]'>Once payment is received, you will receive booking confirmation via email.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='flex justify-center mt-10'>

                        <NavLink to="/bookflight">
                            <button className=" bg-[#209D5A] hover:bg-[#016E33] text-white py-3 pl-[30px] rounded-lg inline-flex justify-center items-center gap-2  transition">
                                Book a flight now
                                <img src="/images/flighticon2.gif" alt="icon" className='w-[58px] h-[24px]'/> 
                                {/* <div className="w-[58px] h-[24px] animate-slowSpin" style={{ backgroundImage: `url(${flightIcon})` }}></div> */}
                            </button></NavLink>

                    </div>
                </div>

            </section>


            <section className="pt-10  pb-10 bg-[#fff]">
                <div className="grid  px-8 lg:px-16 pb-10  lg:gap-8 xl:gap-0 lg:pt-10">
                    <div>
                        <h3 className="text-center lg:text-[48px] text-[30px] text-[#191A15] font-[700] leading-[58.09px]">Do More on AfriPay</h3>
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 pt-5">

                        <div
                            onClick={() => navigate("/health-travel-insurance")}
                            className="mt-3 ">

                            <img src="/images/H&T.svg" alt="logo" className="" />
                        </div>
                        <div
                            onClick={() => navigate("/student-loan")}
                            className="mt-3 ">

                            <img src="/images/SL2.svg" alt="logo" className="" />
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}

export default BookFlight;